import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import additionalFieldsService from "services/additionalFieldsService";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import materialRequisitionService from "services/materialRequisitionService";

@autoinject
export class ProjectMaterialRequisitionAdditionalFields {

    public actions: any = {};
    public dispatchProjectCode: string = "";
    public requisitionId: string = "";
    public readonly: boolean = false;
    public additionalFieldsSource: number = AdditionalFieldsSourceType.Requisition;
    public saveAdditionalFieldsButton: boolean = true;
    public requisition!: MaterialRequisitionModel;

    constructor(private readonly i18n: I18N) {
    }

    public async activate(params: any): Promise<any> {
        await this.bindViewModel(params.dispatchProjectCode, params.requisitionId, params.q);
        this.bindWidget();
    }

    public async bindViewModel(dispatchProjectCode: string, requisitionId: string, queryString: string): Promise<void> {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");

        this.dispatchProjectCode = dispatchProjectCode;
        this.requisitionId = requisitionId;

        this.requisition = await materialRequisitionService.getMaterialRequisition(this.requisitionId);
        this.readonly = this.readonly || !this.requisition.CreatedByCurrentUser;
    }

    public bindWidget(): void {
        this.actions = {
            getAll: additionalFieldsService.getAllForRequisition.bind(this, this.requisitionId),
            setAll: additionalFieldsService.setAllForRequisition.bind(this, this.requisitionId)
        };
    }
}
