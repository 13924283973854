import { autoinject } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import { I18N } from "aurelia-i18n";

import { default as apiHelper } from "helpers/apiHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as notificationHelper } from "helpers/notificationHelper";

import { default as templateService } from "services/templateService";
import materialRequisitionService from "services/materialRequisitionService";
import { default as projectService } from "services/projectService";

@autoinject()
export default class ProjectEquipmentRequisitionItem {

    public actions: any = {};
    public dispatchProjectCode: string = "";
    public requisitionId: string = "";
    public showNotInCatalog: boolean = false;
    public lineNo: string = "";
    public requisitionDate: any = null;
    public catalogBaseUrl: string = "";

    constructor(private readonly i18n: I18N) {
    }

    public bindViewModel(dispatchProjectCode: string, requisitionId: string, requisitionDate: any, lineNo: string): void {
        this.dispatchProjectCode = dispatchProjectCode;
        this.requisitionDate = requisitionDate;
        this.requisitionId = requisitionId;
        this.lineNo = lineNo;

        this.catalogBaseUrl = apiHelper.getBaseUrl() +
            "/catalog/dispatch/projects/materialrequisitions?dispatchTemplateId=" +
            settingHelper.getSelectedDispatchModel();
        this.catalogBaseUrl += "&forProjectEquipment=true";
    }

    public setSpecificFields(item: any): void {
        item.dispatchProjectCode = this.dispatchProjectCode;
        return item;
    }

    public bindWidget(): void {
        this.actions = {
            setSpecificFields: (item: any): void => this.setSpecificFields(item),
            saveMaterialRequisitionItem: (requisitionId: string, lineNo: string, item: any): any => materialRequisitionService.setMaterialRequisitionItemForProject(this.dispatchProjectCode, requisitionId, lineNo, item),
            getActivities: (): any => projectService.getActivitiesForDispatchProject(this.dispatchProjectCode)
        };
    }

    public canActivate(): any {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }
        return true;
    }

    public async activate(params: any): Promise<void> {
        const dispatchProjectCode = params.dispatchProjectCode;
        const requisitionId = params.requisitionId;
        const requisitionDate = dateHelper.dateFromNavigationParameter(params.date);
        const lineNo = params.lineNo;
       
        this.bindViewModel(dispatchProjectCode, requisitionId, requisitionDate, lineNo);
        this.bindWidget();

        const configs = await templateService.getUserTemplateConfigs();
        if (configs !== null) {
            this.showNotInCatalog = configs.ShowNotInCatalogMaterialProject;
        }
    }
}
