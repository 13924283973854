import { inject } from "aurelia-framework";

import _ from "underscore";

import Assert from "core/assert";
import { default as enumHelper } from "helpers/enumHelper";
import UserOptionsManager from "services/user-options-manager";

@inject(UserOptionsManager)
export default class UserAccessService {

    constructor(userOptionsManager) {
        this.userOptionsManager = userOptionsManager;
    }

    async isOptionEnabled(option) {
        const userOptions = await this.userOptionsManager.get();
        return await this.isOptionAllowedInUserOptions(option, userOptions);
    }

    isOptionEnabledSync(option) {
        const userOptions = this.userOptionsManager.getSync();
        return this.isOptionAllowedInUserOptions(option, userOptions);
    }

    isServiceQuotationBTOptionEnabledSync(option) {
        const userOptions = this.userOptionsManager.getSync();
        return this.userHasAccess(option, userOptions) && userOptions.ServiceQuotationsBTEnabled;
    }

    isShopDrawingOptionEnabledSync() {
        const userOptions = this.userOptionsManager.getSync();
        return userOptions.ShopDrawingEnabled;
    }

    isInventoryProjectTransferOptionEnabledSync() {
        const userOptions = this.userOptionsManager.getSync();
        return this.optionSpecificValidation(enumHelper.userOptions.INVENTORYPROJECTTRANSFER, userOptions);
    }

    isRentEquipmentEnabledSync() {
        const userOptions = this.userOptionsManager.getSync();
        return userOptions.RentEquipmentEnabled;
    }

    isCurrencyOptionEnabledSync() {
        const userOptions = this.userOptionsManager.getSync();
        return userOptions.CurrencyEnabled;
    }

    isOptionAllowedInUserOptions(option, userOptions){
        Assert.hasValue(option, "No option provided!");

        if (!userOptions){
            // No company selected yet. We can't have the user options.
            return false;
        }

        return this.userHasAccess(option, userOptions)
             && this.optionSpecificValidation(option, userOptions);
    }

    userHasAccess(option, userOptions){
        if (userOptions.IsAdministrator === true) {
            return true;
        }

        if (userOptions.Options === null || userOptions.Options.length === 0) {
            return false;
        }

        if (option.id === undefined){
            // Section doesn't exists in maestro*. So it can never be enabled, but we still want to show it.
            return true;
        }

        if (userOptions.Options.includes(option.id)) {
            return true;
        }

        const hasActiveChilds = _.chain(enumHelper.userOptions)
            .where({ parentId: option.id })
            .map((enu) => { return enu.id; })
            .intersection(userOptions.Options)
            .some()
            .value();

        return hasActiveChilds;
    }

    optionSpecificValidation(option, userOptions) {
        switch (option) {
            case enumHelper.userOptions.APPROVAL:
                return userOptions.WorkflowApprovalsEnabled;
            case enumHelper.userOptions.DAILYENTRYEQUIPMENTCOUNTERS:
                return userOptions.DailyEntryEquipmentCountersEnabled;
            case enumHelper.userOptions.DAILYENTRYTASKSPROGRESS:
                return userOptions.DailyEntryTasksProgressEnabled;
            case enumHelper.userOptions.SERVICEQUOTATIONS:
                return userOptions.ServiceQuotationsEnabled;
            case enumHelper.userOptions.EQUIPMENTCOUNTERS:
                return userOptions.EquipmentCountersEnabled;
            case enumHelper.userOptions.RECEIPTOFGOODS:
                return userOptions.ReceiptOfGoodsEnabled;
            case enumHelper.userOptions.TIMEKEEPING:
                return userOptions.TimekeepingEnabled;
            case enumHelper.userOptions.INVENTORYPROJECTTRANSFER:
                return userOptions.InventoryProjectTransferEnabled;
            case enumHelper.userOptions.INVENTORYSITETRANSFER:
                return userOptions.InventorySiteTransferEnabled;
            case enumHelper.userOptions.MAESTROBI_HOME:
                return userOptions.MaestroBiEnabled;
            case enumHelper.userOptions.MATERIALUSED:
                return userOptions.InventoryProjectTransferEnabled;
            default:
                return true;
        }
    }
}
