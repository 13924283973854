import queryStringHelper from "helpers/queryStringHelper";
import materialRequisitionService from "services/materialRequisitionService";
import { DispatchProjectService } from "services/dispatch-project-service";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

@autoinject
export class ProjectEquipmentRequisitionEdit {
    public actions: any = {};
    public dispatchProjectCode: string = "";
    public requisitionId: string = "";
    public isProjectResponsible: boolean = false;

    constructor(private readonly i18n: I18N, private readonly dispatchProjectService: DispatchProjectService) {
    }

    public async canActivate(params: any): Promise<any> {
        this.dispatchProjectCode = params.dispatchProjectCode;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
        return true;
    }

    public activate(params: any): any {
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.requisitionId = params.requisitionId;
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);
    }

    public bind(): any {
        this.actions = {
            getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(materialRequisitionService, this.requisitionId),
            saveMaterialRequisitionService: materialRequisitionService.setEquipmentRequisitionHeaderForProject.bind(materialRequisitionService, this.dispatchProjectCode),
            setSpecificFields: this.setSpecificFields
        };

    }

    public setSpecificFields(item: any): any {
        item.dispatchProjectCode = this.dispatchProjectCode;
        return item;
    }

    private async checkIsProjectActive(): Promise<void> {
        await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }
}
