import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";
import employeeService from "services/employeeService";
import notificationHelper from "helpers/notificationHelper";
import moment from "moment";
import routerHelper from "helpers/routerHelper";
import { TimeListHelper } from "helpers/time-list-helper";
import dateHelper from "helpers/dateHelper";
import val from "core/val";

@autoinject
export class EmployeeAddNonAvailability {

    @bindable public endDate: Date = new Date();
    @bindable public startDate: Date = new Date();
    @bindable public employeeId: number = 0;
    @bindable public increment: number = 15;
    @bindable public unAvailabilityId: number = 0;
    @bindable public comments: string = "";

    public typeId: any;
    public labelStart: string = this.i18n.tr("StartTime");
    public labelEnd: string = this.i18n.tr("EndTime");
    public isResponsible: boolean = false;
    public readonly: boolean = false;
    public selectedType: any;
    public timeOptions: any[] = [];
    public val: any = val;

    constructor(private readonly i18n: I18N) {
    }

    public async bind(): Promise<any> {
        this.timeOptions = TimeListHelper.loadTimeList(this.increment);

        if (this.unAvailabilityId && this.unAvailabilityId !== 0) {
            const unAvailabilityItem = await employeeService.getNonAvailability(this.unAvailabilityId);
            if (unAvailabilityItem) {
                this.selectedType = { id: unAvailabilityItem.TypeId, text: unAvailabilityItem.TypeId + " - " + unAvailabilityItem.TypeDesc };
            }
        }
    }

    public get getTypes(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                await employeeService.getNonAvailabilityTypes(params.data.filter, params.data.page || 1).then((result: any) => {
                    return success(result);
                });
            },
            mapResults: (item: any): any => {
                return { id: item.Id, text: item.Id + " - " + item.Description };
            }
        };
    }

    public isValid(): boolean {
        if (!this.selectedType || !this.selectedType.id) {
            notificationHelper.showError(this.i18n.tr("err_TypeRequired"));
            return false;
        }

        if (moment(this.endDate).isSameOrBefore(moment(this.startDate))) {
            notificationHelper.showError(this.labelEnd + this.i18n.tr("err_GreatherThan") + this.labelStart);
            return false;
        }

        return true;
    }

    public async save(): Promise<void> {
        if (this.isValid()) {

            const data = {
                Id: this.unAvailabilityId,
                EndDate: dateHelper.formatDateToSend(this.endDate),
                StartDate: dateHelper.formatDateToSend(this.startDate),
                TypeId: this.selectedType.id,
                Comments: this.comments
            };

            try {
                await employeeService.setNonAvailabilityForEmployee(this.employeeId, data);
                routerHelper.navigateBack();
            } catch {
                //do nothing stay here
            }
           
        }
    }

}
