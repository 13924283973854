import { MaterialRequisitionItemModel } from "api/models/company/material-requisition/material-requisition-item-model";
import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import enumHelper from "helpers/enumHelper";
import routerHelper from "helpers/routerHelper";
import { StringHelper } from "helpers/string-helper";
import moment from "moment";
import notificationHelper from "helpers/notificationHelper";
import materialRequisitionService from "services/materialRequisitionService";
import { MaterialRequisitionSummaryModel } from "api/models/company/material-requisition/material-requisition-summary-model";
import labelHelper from "helpers/labelHelper";
import { UserSecurityLookupModel } from "api/models/common/settings/user-security-lookup-model";
import userService from "services/userService";
import { CloneHelper } from "helpers/cloneHelper";
import { parse } from "querystring";
import { NavigationContext } from "core/navigation-context";
import { default as _ } from "underscore";
import { EventAggregator } from "aurelia-event-aggregator";
import documentService from "services/documentService";
import { LocationModel } from "api/models/company/location-model";
import { ServiceCallService } from "services/service-call-service";
import { DispatchProjectService } from "services/dispatch-project-service";
import { LocationType } from "api/enums/location-type";
import { RequisitionProcessStatus } from "api/enums/requisition-process-status";

@autoinject
export class MaterialRequisitionEdit {

    public dateHelper: typeof dateHelper = dateHelper;
    public enumHelper: typeof enumHelper = enumHelper;
    public routerHelper: typeof routerHelper = routerHelper;

    @bindable public actions: any = {};
    @bindable public readonly: boolean = false;
    @bindable public requisitionId: string = "-1";

    @bindable public dispatchProjectCode: string = "";
    @bindable public parentModel: any = null;
    @bindable public isProject: boolean = false;
    @bindable public dispatchId: any;
    @bindable public workOrderId: any;
    @bindable public forProjectEquipment: boolean = false;

    public dateEntered: Date = moment().toDate();
    public dateRequired: Date = moment().toDate();
    public isNew: boolean = true; //todo verify if true or false at init
    public requisition!: MaterialRequisitionModel;
    public status: string = "";
    public btnText: string = "";
    public requisitionIdDisplay: string = "";
    public reqItemsVisible: any;
    public txtNoReqItem: string = "";
    public isAddItemDisabled: boolean = true; //todo verify if true or false at init
    public isAddResourceDisabled: boolean = true; //todo verify if true or false at init
    public isFromProjectModule: boolean = false;
    public statusList: Array<{ text: string; id: number; }> = [];
    public selectedUser: any | null = null;
    public navigationContext: NavigationContext;
    public subscription: any;
    public pictures: any = [];
    public deleteAction!: (documentId: number) => any;
    public acceptedTypes: any[] = [LocationType.Alternative, LocationType.Customer, LocationType.Project, LocationType.Contact, LocationType.Supplier, LocationType.Company, LocationType.List];

    public unModifiedRequisition: MaterialRequisitionModel | null = null;
    public partiallyProcessed: string = this.i18n.tr("PartiallyProcessed");
    public completelyProcessed: string = this.i18n.tr("CompletelyProcessed");
    public readonly requisitionProcessStatus: typeof RequisitionProcessStatus = RequisitionProcessStatus;

    @computedFrom("status", "dateEntered", "dateRequired", "selectedUser", "unModifiedRequisition")
    public get isDirtyCheck(): boolean {
        if (!this.parentModel) {
            return false;
        }

        if (this.readonly || !this.unModifiedRequisition) {
            this.parentModel.isDirty = false;
            return this.parentModel.isDirty;
        }

        this.unModifiedRequisition.DateEntered = this.unModifiedRequisition.DateEntered ? new Date(new Date(this.unModifiedRequisition.DateEntered!.toString()).getFullYear(), new Date(new Date(this.unModifiedRequisition.DateEntered!.toString())).getMonth(), new Date(new Date(this.unModifiedRequisition.DateEntered!.toString())).getDate(), 0, 0, 0, 0) : this.unModifiedRequisition.DateEntered;
        this.unModifiedRequisition.RequiredDate = this.unModifiedRequisition.RequiredDate ? new Date(new Date(this.unModifiedRequisition.RequiredDate!.toString()).getFullYear(), new Date(new Date(this.unModifiedRequisition.RequiredDate!.toString())).getMonth(), new Date(new Date(this.unModifiedRequisition.RequiredDate!.toString())).getDate(), 0, 0, 0, 0) : this.unModifiedRequisition.RequiredDate;
        const stringifyUnmodified = JSON.stringify(this.unModifiedRequisition).replace(/[^0-9A-Z]+/gi, "");

        this.setCurrentRequisition();
        this.requisition.DateEntered = this.requisition.DateEntered ? new Date(new Date(this.requisition.DateEntered!.toString()).getFullYear(), new Date(new Date(this.requisition.DateEntered!.toString())).getMonth(), new Date(new Date(this.requisition.DateEntered!.toString())).getDate(), 0, 0, 0, 0) : this.requisition.DateEntered;
        this.requisition.RequiredDate = this.requisition.RequiredDate ? new Date(new Date(this.requisition.RequiredDate!.toString()).getFullYear(), new Date(new Date(this.requisition.RequiredDate!.toString())).getMonth(), new Date(new Date(this.requisition.RequiredDate!.toString())).getDate(), 0, 0, 0, 0) : this.requisition.RequiredDate;
        const stringifyCurrent = JSON.stringify(this.requisition).replace(/[^0-9A-Z]+/gi, "");

        this.parentModel.isDirty = stringifyUnmodified !== stringifyCurrent;

        return this.parentModel.isDirty;
    }

    constructor(private readonly i18n: I18N, public readonly eventAggregator: EventAggregator, navigationContext: NavigationContext, private readonly serviceCallService: ServiceCallService, private readonly dispatchProjectService: DispatchProjectService) {
        this.navigationContext = navigationContext;
    }

    public async bind(): Promise<any> {
        this.deleteAction = documentService.deleteDocument.bind(documentService);
        this.subscription = this.eventAggregator.subscribe("document-upload-finish", () => this.reloadDocuments());
        await this.loadData();
        this.statusList = [{ text: this.i18n.tr("Ongoing"), id: 0 }, { text: this.i18n.tr("Completed_F"), id: 1 }];
    }

    public async reloadDocuments(): Promise<void> {
        if (!this.requisitionId) {
            return;
        }
        this.pictures = (await materialRequisitionService.getMaterialRequisition(this.requisitionId)).Pictures;
    }

    public genUrl(line: number): string {
        return routerHelper.getRelativeUrl("item", dateHelper.dateToNavigationParameter(this.dateRequired), line) + routerHelper.addQuerystring({ readonly: this.readonly });
    }

    public detached(): any {
        if (this.subscription) {
            this.subscription.dispose();
        }
    }

    public addUrl(): string {
        const date = dateHelper.dateToNavigationParameter(this.dateRequired);
        let temp = routerHelper.getRelativeUrl("");

        if (StringHelper.endsWith(temp, "edit")) {
            temp += "/";
            temp += this.requisitionId;
            temp += "/item/";
            temp += date;

            return temp;
        }
        return routerHelper.getRelativeUrl("item", date);
    }

    public addProjectResourceUrl(): string {
        const date = dateHelper.dateToNavigationParameter(this.dateRequired);
        let temp = routerHelper.getRelativeUrl("");

        if (StringHelper.endsWith(temp, "edit")) {
            temp += "/";
            temp += this.requisitionId;
            temp += "/projectResource/";
            temp += date;

            return temp;
        }
        return routerHelper.getRelativeUrl("projectResource", date);
    }

    public deleteItem(item: MaterialRequisitionItemModel): void {
        notificationHelper.showDeleteConfirmation().then((success: any) => {
            if (success) {
                routerHelper.showLoading();
                materialRequisitionService.delMaterialRequisitionItem(item.Id!, item.Line).then(() => {
                    this.loadData();
                });
                routerHelper.hideLoading();
            }
        });
    }

    public setCurrentRequisition(): void {
        this.requisition.DateEntered = this.dateEntered;
        this.requisition.RequiredDate = this.dateRequired;
        this.requisition.MobileStatus = this.status;
        this.requisition.Id = this.requisitionId === "-1" ? null : this.requisitionId;
        this.requisition.ProcessedBy = this.selectedUser === null ? 0 : this.selectedUser.id;
    }

    public saveRequisition(addPicture: any): void {
        this.setCurrentRequisition();

        this.requisition = this.actions.setSpecificFields(this.requisition);

        routerHelper.showLoading();

        this.actions.saveMaterialRequisitionService(this.requisition).then((data: MaterialRequisitionSummaryModel) => {
            this.unModifiedRequisition = null;

            this.requisitionId = data.Id!;

            if (this.isNew) {
                this.isNew = false;
                routerHelper.navigate(routerHelper.getRelativeUrl(encodeURIComponent(data.Id!)) + routerHelper.addQuerystring({ readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode }), { replace: true, trigger: true });
            }
        }).always(() => {
            routerHelper.hideLoading();
        });
    }

    public get lookupUser(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                userService.getAllUsersLookup(params.data.filter, params.data.page || 1).then(
                    (result: UserSecurityLookupModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: UserSecurityLookupModel): any => {
                return { id: item.Id, text: item.Code + " - " + item.Description };
            }
        };
    }

    public goToPictureAdd(requisitionId: string): void {
        this.checkIfCanLeavePage().then((confirm: boolean) => {
            if (confirm) {
                const route = this.navigationContext.getCurrentRouteName() + "_Documents_Add";
                routerHelper.navigateToRoute(route, this.buildNavigationParameters(encodeURIComponent(requisitionId)));
            }
        });
    }

    public goToMemo(): void {
        this.checkIfCanLeavePage().then((confirm: boolean) => {
            if (confirm) {
                const route = this.navigationContext.getCurrentRouteName() + "_Memo";
                routerHelper.navigateToRoute(route, this.buildNavigationParameters(encodeURIComponent(this.requisition.Id!)));
            }
        });
    }

    public goToAdditionalFields(): void {
        this.checkIfCanLeavePage().then((confirm: boolean) => {
            if (confirm) {
                const route = this.navigationContext.getCurrentRouteName() + "_AdditionalFields";
                routerHelper.navigateToRoute(route, this.buildNavigationParameters(encodeURIComponent(this.requisition.Id!)));
            }
        });
    }

    public async checkIfCanLeavePage(): Promise<boolean> {
        if (this.isDirtyCheck) {
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return false;
            }
            this.parentModel.isDirty = false;
        }
        return true;
    }

    public addPicture(): void {
        this.goToPictureAdd(this.requisitionId);
    }

    public buildNavigationParameters(requisitionId: string, additionnalQueryStringParameters?: any): any {
        const navigationParams = this.navigationContext.getParameters();
        let queryParams = this.navigationContext.getQueryParameters();

        queryParams = routerHelper.getQuerystring(queryParams);
        queryParams = _.extend(queryParams, additionnalQueryStringParameters);
        queryParams = { q: routerHelper.buildQueryString(queryParams) };

        const navigationParameters = _.extend({}, navigationParams, queryParams);

        navigationParameters.requisitionId = requisitionId;

        navigationParameters.editId = requisitionId;

        return navigationParameters;
    }

    public async confirmComplete(): Promise<boolean> {
        return await notificationHelper.showConfirmation(this.i18n.tr("msg_CompleteRequistionConfirmation"));
    }

    public completeRequisition(): void {
        this.confirmComplete().then((confirm: boolean) => {
            if (confirm) {
                routerHelper.showLoading();

                this.setCurrentRequisition();

                this.requisition = this.actions.setSpecificFields(this.requisition);

                this.actions.saveMaterialRequisitionService(this.requisition).then((data: MaterialRequisitionSummaryModel) => {
                    this.unModifiedRequisition = null;
                    materialRequisitionService.completeRequisition(this.requisition.Id!).then(() => {
                        routerHelper.navigateBack();
                    });
                }).always(() => {
                    routerHelper.hideLoading();
                });
            }
        });
    }

    public async getAddress(addressType: any, filter: any, pagingInfo: any, requestConfig: any): Promise<LocationModel[] | null> {
        const prefix = this.requisition && this.requisition.Prefix ? this.requisition.Prefix : null;
        if (this.isProject) {
            return await this.dispatchProjectService.getProjectDeliveryAddress(this.dispatchProjectCode, addressType, null, prefix, this.dispatchId ? this.dispatchId : null, this.workOrderId ? this.workOrderId : null, filter, pagingInfo, requestConfig);
        } else {
            return await this.serviceCallService.GetDeliveryAddress(this.dispatchId, addressType, null, prefix, filter, pagingInfo, requestConfig);
        }
    }

    private async loadData(): Promise<void> {
        await this.actions.getMaterialRequisition().then((result: MaterialRequisitionModel) => {
            this.requisition = result;

            this.unModifiedRequisition = CloneHelper.deepClone(this.requisition);

            if (this.requisition.Id === null) {
                this.pictures = [];
                this.initValuesForNewRequisition();
            } else {
                if (this.requisition.ProcessedBy !== 0) {
                    this.selectedUser = this.createDefaultMaSelectElement(this.requisition.ProcessedBy, this.requisition.ProcessedByName);
                }

                this.pictures = this.requisition.Pictures;

                this.readonly = !this.requisition.CreatedByCurrentUser;

                this.isNew = false;
            }

            this.updateSelf();
            this.initDropdown();
        });
        if (this.isNew) {
            if (this.isProject) {
                this.requisition.DeliveryAddress = (await this.dispatchProjectService.GetProjectDeliveryAddress(this.dispatchProjectCode, this.forProjectEquipment ? LocationType.Company : null, undefined, undefined, this.dispatchId ? this.dispatchId : null, this.workOrderId ? this.workOrderId : null))![0];
            } else {
                this.requisition.DeliveryAddress = (await this.serviceCallService.GetDeliveryAddress(this.dispatchId, null, undefined, undefined))![0];
            }
        }
    }

    private updateFlags(): void {
        this.readonly = (this.requisition.MobileStatus !== "0") || this.readonly;
        this.isAddItemDisabled = this.isNew || this.readonly;

        this.isAddResourceDisabled = this.isAddItemDisabled || !this.requisition.HasResource;
        this.reqItemsVisible = !this.isNew && this.requisition.Items && this.requisition.Items.length > 0;

        this.isFromProjectModule = !!this.dispatchProjectCode;

    }

    private updateTextsAndValues(): void {
        this.btnText = this.isNew ? this.i18n.tr("Add") : this.i18n.tr("Save");
        this.requisitionIdDisplay = this.isNew ? this.i18n.tr("New_F") : this.requisitionId;
        this.txtNoReqItem = this.isNew ? this.i18n.tr("lstNoData_RequisitionItemUnsaved") : this.i18n.tr("lstNoData_RequisitionItem");

        this.status = this.requisition.MobileStatus!;
        this.dateEntered = this.requisition.DateEntered!;
        this.dateRequired = this.requisition.RequiredDate!;
    }

    private updateSelf(): void {
        this.updateFlags();
        this.updateTextsAndValues();
    }

    private createDefaultMaSelectElement(id: number, description: string | null): any {
        return { id: id, text: description ? id + " - " + description : id };
    }

    private initDropdown(): void {
        const element: any = jQuery("#ddlStatus");
        element.select2({
            minimumResultsForSearch: 999,
            width: "100%",
            language: labelHelper.getDefaultSelect2Labels(),
            data: !this.readonly ? [
                { text: this.i18n.tr("Ongoing"), id: 0 },
                { text: this.i18n.tr("Completed_F"), id: 1 }
            ] : [
                    { text: this.i18n.tr("Ongoing"), id: 0 },
                    { text: this.i18n.tr("Completed_F"), id: 1 },
                    { text: this.i18n.tr("Confirmed_F"), id: "P" }
                ]
        }).on("change", (e: any) => {
            this.status = e.target.value;
        }).val(this.status).trigger("change");
    }

    private initValuesForNewRequisition(): void {
        this.isNew = true;

        this.requisition.MobileStatus = "0";
        this.requisition.DateEntered = moment().toDate();
        this.requisition.RequiredDate = moment().toDate();
        this.requisition.Prefix = "";
        this.requisition.UserNo = -1;
        this.requisition.Items = [];
    }
}
