import { inject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { default as _ } from "underscore";

import logger from "core/logger";

import { ServiceDispatchType } from "enums/service-dispatch-type";

import { default as dateHelper } from "helpers/dateHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as labelHelper } from "helpers/labelHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { AdditionalFieldsHelper } from "helpers/additional-fields-helper";

import { default as defaultService } from "services/defaultService";
import { default as dispatchService } from "services/dispatchService";
import { default as serviceService } from "services/serviceService";
import UserAccessService from "services/user-access-service";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";

import RouteRepository, { NavigationNew } from "repositories/routeRepository";
import { WorkOrderMaintenanceType } from "api/enums/work-order-maintenance-type";
import { nameof as nameof_WorkOrderMaintenanceType } from "api/enums/work-order-maintenance-type";
import { EmergencyTypes } from "enums/emergency-types";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { default as stringHelper } from "helpers/stringHelper";

@inject(I18N, RouteRepository, UserAccessService, ServiceCallQuotationSecurityService, AdditionalFieldsHelper)
export default class Detail {
    selectedTeamLeader = {};
    teamLeaderName = "";
    isTeamLeader = false;
    dispatchId = "";
    serviceType = "";
    detail = {};
    theme="";

    currentActionUrl;
    subheaderTitle;
    subheaderCssClass;
    queryStringReadonly = false;
    presenceValues = [];
    presenceEnumObj = {};
    fromHistory = false;
    btnLinks = [];
    nameof_WorkOrderMaintenanceType = nameof_WorkOrderMaintenanceType;
    emergencyTypeEnum = EmergencyTypes;

    loadGroupedEmployees = {
        transport: (params, success, failure) => {
            dispatchService.getGroupedEmployees(this.detail.DispatchId, true, true)
                .done(success)
                .fail(failure);
        },
        mapResults: (item) => {
            item.id = item.Id;
            item.text = item.Id + " - " + item.FirstName + " " + item.LastName;

            return item;
        }
    };

    securitySettings = null;

    @computedFrom("detail")
    get isClosed() {
        return this.detail.IsClosed || this.detail.Presence === this.enumPresence.COMPLETED.id || this.detail.Presence === this.enumPresence.ONHOLD.id;
    }

    @computedFrom("queryStringReadonly", "detail")
    get isReadonly() {
        return this.queryStringReadonly || this.detail.IsReadonly === true;
    }

    @computedFrom("detail")
    get materialCountToDisplay() {
        return this.detail.MaterialCount;
    }

    @computedFrom("detail")
    get materialLinkTitle() {
        return this.i18n.tr("MaterialAndBillingItem");
    }

    @computedFrom("detail")
    get materialReservedLinkTitle() {
        if (this.isReservedMaterials) {
            return this.i18n.tr("MaterialAndBillingItem_Reserved");
        }
        return this.i18n.tr("MaterialAndBillingItem_OrderedOrReserved");
    }

    @computedFrom("detail")
    get materialReservedCountToDisplay() {
        return this.detail.ReservedMaterialCount;
    }

    @computedFrom("detail")
    get canAcceptOrRefuse() {
        if (!this.detail) {
            return false;
        }

        var pres = this.detail.Presence;

        return (pres === this.enumPresence.NEW.id ||
                pres === this.enumPresence.ACCEPTED.id ||
                pres === this.enumPresence.DECLINED.id ||
                pres === this.enumPresence.STOP.id) &&
            !this.detail.IsReadonly;
    }

    @computedFrom("detail")
    get isAcceptedAndStarted() {
        return this.detail.Presence === this.enumPresence.ARRIVED.id ||
            this.detail.Presence === this.enumPresence.COMPLETED.id ||
            this.detail.Presence === this.enumPresence.ONHOLD.id;
    }

    @computedFrom("detail")
    get oneDayWorkDuration() {
        if (this.detail && this.detail.AssignedDate && this.detail.AssignedEndDate)
            return !dateHelper.isAfter(this.detail.AssignedEndDate, this.detail.AssignedDate);
        return false;
    }

    constructor(i18n, routeRepository, userAccessService, serviceCallQuotationSecurityService, additionalFieldsHelper) {
        this.dateHelper = dateHelper;
        this.routerHelper = routerHelper;
        this.labelHelper = labelHelper;
        this.enumHelper = enumHelper;
        this.stringHelper = stringHelper;
        this.enumPresence = enumHelper.servicePresence();
        this.parseFloat = parseFloat;

        this.i18n = i18n;
        this.routeRepository = routeRepository;
        this.userAccessService = userAccessService;
        this.serviceCallQuotationSecurityService = serviceCallQuotationSecurityService;
        this.additionalFieldsHelper = additionalFieldsHelper;
    }

    async activate(params) {
        await this.initSecuritySettings();

        this.bindViewModel(params.serviceType, params.dispatchId, params.q);
        await this.loadData(params.serviceType, params.dispatchId);
    }

    async accept() {
        await this.saveAccept(this.detail);
    }

    async setDispatchOnHold() {
        routerHelper.showLoading();
        try {
           
            await dispatchService.setServiceCallOnHold(this.dispatchId).promise();
            await this.loadData(this.serviceType, this.dispatchId);
        } finally {
            routerHelper.hideLoading();
        }
    }

    async setDispatchArrived() {
        routerHelper.showLoading();
        try {

            await dispatchService.setServiceCallArrived(this.dispatchId).promise();
            await this.loadData(this.serviceType, this.dispatchId);
        } finally {
            routerHelper.hideLoading();
        }
    }

    async refuse() {
        try {
            const data = await dispatchService.getGroupedEmployees(this.detail.DispatchId, true, true).promise();

            if (this.isTeamLeader && data.length === 0) {
                notificationHelper.showWarning(this.i18n.tr("err_AllTechniciensHaveCompletedTheVisit_Message"));
            } else if (this.isTeamLeader) {
                notificationHelper.showWarning(this.i18n.tr("err_YouMustChangeThePersonInCharge_Message"));
            } else {
                routerHelper.navigate(this.genUrl("action/refuse", this.isClosed));
            }
        } finally {
            routerHelper.hideLoading();
        }
    }

    genReadonlyUrl(routeName, readonly) {
        return routerHelper.getRelativeUrl(routeName, _.rest(arguments, 2)) + routerHelper.addQuerystring({
            entityId: this.detail.Id,
            readonly: this.isReadonly || readonly
        });
    }

    genUrl(routeName, readonlyCallStatus) {
        let url = routerHelper.getRelativeUrl(routeName);
        let queryString = {
            readonly: readonlyCallStatus || this.isReadonly,
            entityId: this.detail.Id,
            workOrderType: this.detail.TypeId,
            isClosed: this.isClosed,
            serviceCallId: this.detail.Id,
            maintenanceEquipmentCount: this.detail.MaintenanceEquipmentCount,
            projectCode: this.detail.Contract ? this.detail.Contract.Id : null,
            dispatchProjectCode: this.detail.Contract ? this.detail.Contract.Id : null,
            fromHistory: this.fromHistory,
            isMaintenanceWO: this.detail.MaintenanceType === WorkOrderMaintenanceType.Pep || this.detail.MaintenanceType === WorkOrderMaintenanceType.Regular
        };

        if (this.detail.VisitTimestamp) {
            queryString.ts = this.detail.VisitTimestamp;
        } else if (this.detail.WorkOrderTimestamp) {
            queryString.ts = this.detail.WorkOrderTimestamp;
        }

        if (this.detail.Presence) {
            queryString.presence = this.detail.Presence;
        }

        url += routerHelper.addQuerystring(queryString);

        return url;
    }

    genUrlForComplete(routeName, assignedDate, assignedEndDate, timeIncrement) {
        return routerHelper.getRelativeUrl(routeName, assignedDate, assignedEndDate, timeIncrement) + routerHelper.addQuerystring({
            readonly: this.isReadonly || this.isClosed, isMaintenanceWorkOrder: this.detail.IsMaintenanceWorkOrder
        });
    }

    navigateToComplete() {
        if (!this.detail.ReadyToComplete.AllRequiredAdditionalFieldsAreEntered) {
            var warning = this.additionalFieldsHelper.getRequiredRessourceBySourceType(AdditionalFieldsSourceType.CallReturn);
            notificationHelper.showWarning(warning, "", { timeOut: 0 });
        }

        if (!this.detail.ReadyToComplete.AllRequiredServiceCallAdditionalFieldsAreEntered) {
            var warning2 = this.additionalFieldsHelper.getRequiredRessourceBySourceType(AdditionalFieldsSourceType.ServiceCallSpecific);
            notificationHelper.showWarning(warning2, "", { timeOut: 0 });
        }

        if (!this.detail.ReadyToComplete.AllRequiredEquipmentTaskMaintenanceEntered) {
            notificationHelper.showWarning(this.i18n.tr("err_Service_Equipment_Maintenance_Task_Required"), "", { timeOut: 0 });
        }

        if (!this.detail.ReadyToComplete.AllRequiredInspectionEntered) {
            notificationHelper.showWarning(this.i18n.tr("err_Service_Call_Inspection_Required"), "", { timeOut: 0 });
        }

        if (this.detail.EquipmentDoneCount === 0) {
            notificationHelper.showWarning(this.i18n.tr("err_Service_Call_Equipment_No_Check_Complete"), "", { timeOut: 0 });
        }

        if (!this.detail.ReadyToComplete.RequiredVisitDetailsEntered) {
            notificationHelper.showWarning(this.i18n.tr("err_Service_Call_Note_Required_Complete"), "", { timeOut: 0 });
        }
        
        if (!this.detail.ReadyToComplete.RequiredMaterialEntered) {
            notificationHelper.showWarning(this.i18n.tr("err_Service_Call_Material_Required_Complete"), "", { timeOut: 0 });
        }

        if (!this.detail.ReadyToComplete.RequiredLaborEntered) {
            notificationHelper.showWarning(this.i18n.tr("err_Service_Call_Labor_Required_Complete"), "", { timeOut: 0 });
        }
        
        if (!this.detail.ReadyToComplete.RequiredDocumentEntered) {
            notificationHelper.showWarning(this.i18n.tr("err_Service_Call_Document_Required_Complete"), "", { timeOut: 0 });
        }

        if (!this.detail.ReadyToComplete.IsReadyToComplete) {
            return;
        }

        routerHelper.navigateToRoute(
            this.routeRepository.routes.Service_Detail_Action_Complete.name,
            {
                serviceType: this.serviceType,
                dispatchId: this.detail.DispatchId,
                assignedDate: this.detail.AssignedDate,
                dateFrom: this.detail.AssignedEndDate,
                increment: this.detail.TimeIncrement,
                q: routerHelper.buildQueryString({
                    readonly: this.isReadonly || this.isClosed, isMaintenanceWorkOrder: this.detail.IsMaintenanceWorkOrder,
                    entityId: this.detail.entityId,
                })
            }
        );
    }

    async road() {
        await this.validateOnRoad(this.detail);
    }

    async stopTravel() {
        if (this.isTeamLeader) {
            notificationHelper.showWarning(this.i18n.tr("err_YouMustChangeThePersonInCharge_Message"));
        } else {
            const success = await notificationHelper.showDialogYesNo(this.i18n.tr("msg_StopTravelConfirmation"));

            if (success) {
                routerHelper.showLoading();
                try {
                    await dispatchService.setServiceCallEnRouteStop(this.detail.DispatchId).promise();
                    routerHelper.navigateBack();
                } finally {
                    routerHelper.hideLoading();
                }
            }
        }
    }

    async saveAccept(model, assignDate) {
        routerHelper.showLoading();
        try {
            var presenceBeforeSaveAccept = this.detail.Presence;
            const data = await dispatchService.setServiceCallAccept(model.DispatchId, assignDate).promise();
            this.setDetail(data);
            await this.setViewModelProperties();

            if (presenceBeforeSaveAccept != this.enumPresence.ACCEPTED.id) {
                dispatchService.serviceCallAcceptNotify(model.DispatchId);
            }

        } finally {
            routerHelper.hideLoading();
        }
    }

    async onNewDispatchLeaderSelected(newPersonInCharge) {
        try {
            await dispatchService.setTeamLeader(this.detail.DispatchId, newPersonInCharge.id).promise();

            this.teamLeaderName = newPersonInCharge.FirstName + " " + newPersonInCharge.LastName;
            this.isTeamLeader = false;

            this.loadData(this.serviceType, this.dispatchId, true);
        } catch (e) {
            logger.error(e);
            this.resetTeamLeader();
        }
    }

    async loadData(serviceType, dispatchId, isReload) {
        const detailsPromise = serviceService.getDetail(serviceType, dispatchId, this.fromHistory).promise();
        const clientPromise = dispatchService.getClient(dispatchId).promise();

        const [details, client] = await Promise.all([detailsPromise, clientPromise]);

        await this.bindLoadedData(details, client, serviceType, dispatchId, isReload);
    }

    async bindLoadedData(data, client, serviceType, dispatchId, isReload) {
        if (client) {
            this.client = client;
        }

        this.setDetail(data);
        defaultService.setCurrentDispatchTemplateCode(data.DispatchTemplate);

        const result = await serviceService.getIsReservedMaterials(serviceType, dispatchId).promise();

        this.isReservedMaterials = result.ReserveRequiredMaterialForCallReturns;
        this.purchaseOrdersEnabled = result.PurchaseOrdersEnabled;

        if (!isReload) {
            await this.setViewModelProperties();
        }

        this.theme = this.detail.ReadyToComplete.AllRequiredAdditionalFieldsAreEntered && this.detail.ReadyToComplete.AllRequiredServiceCallAdditionalFieldsAreEntered && this.detail.ReadyToComplete.AllRequiredEquipmentTaskMaintenanceEntered && this.detail.ReadyToComplete.RequiredVisitDetailsEntered && this.detail.ReadyToComplete.RequiredMaterialEntered && this.detail.ReadyToComplete.RequiredLaborEntered && this.detail.ReadyToComplete.RequiredDocumentEntered ? "success" : "warning";
    }

    bindViewModel(serviceType, dispatchId, queryString) {
        this.presenceValues = _.values(this.enumPresence);
        this.dispatchId = dispatchId;
        this.serviceType = serviceType;
        this.queryStringReadonly = queryStringHelper.parseReadonly(queryString);
        this.fromHistory = queryStringHelper.parse(queryString, "fromHistory") === "true";
    }

    async setViewModelProperties() {
        this.presenceEnumObj = _.first(_.where(this.presenceValues, {
            id: this.detail.Presence
        }));

        this.resetTeamLeader();
        this.setDefaultValues();
        this.setCurrentActionUrl();
        this.setLabels();
        await this.setButtonLinks();
    }

    resetTeamLeader() {
        this.selectedTeamLeader = {};
        this.teamLeaderName = this.detail.TeamLeaderName;
        this.isTeamLeader = this.detail.IsTeamLeader;
    }

    setDefaultValues() {
        defaultService.setInventorySite(this.detail.Site);
    }

    setCurrentActionUrl() {
        if (this.detail.Presence === this.enumPresence.ONMYWAY.id) {
            this.currentActionUrl = routerHelper.getRelativeUrl("action/road", this.detail.TravelStartDate, this.detail.AssignedDate, this.detail.TimeIncrement) + routerHelper.addQuerystring({
                readonly: this.isReadonly || this.isClosed
            });
        } else if (this.detail.Presence === this.enumPresence.ARRIVED.id) {
            this.currentActionUrl = routerHelper.getRelativeUrl("action/start", this.detail.AssignedDate, this.detail.AssignedEndDate, this.detail.TimeIncrement) + routerHelper.addQuerystring({
                readonly: this.isReadonly || this.isClosed
            });
        } else if (this.detail.Presence === this.enumPresence.COMPLETED.id) {
            this.currentActionUrl = routerHelper.getRelativeUrl("action/complete", this.detail.AssignedEndDate) + routerHelper.addQuerystring({
                readonly: this.isReadonly || this.isClosed
            });
        } else {
            this.currentAction = "";
        }
    }

    setLabels() {
        const serviceTypePrefix = this.detail.ServiceType === ServiceDispatchType.Service ?
            labelHelper.getCallTypeLabel(this.detail.CallType) :
            this.i18n.tr("WorkOrder");

        this.subheaderTitle = serviceTypePrefix + " " + this.detail.Id + " - " + this.presenceEnumObj.label;

        let subheaderCssClass = this.presenceEnumObj.icon;
        subheaderCssClass += this.presenceEnumObj.id === this.enumPresence.DECLINED.id ? " red" : "";
        this.subheaderCssClass = subheaderCssClass;
    }

    async setButtonLinks() {
        this.btnLinks = [
            {
                id: "MaintenanceCounters",
                disabled: false,
                disabledAdd: false,
                display: this.detail.MaintenanceType === WorkOrderMaintenanceType.Pep || this.detail.MaintenanceType === WorkOrderMaintenanceType.Regular || this.detail.MaintenanceType === WorkOrderMaintenanceType.Repair,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: "",
                linkMain: this.genUrl("maintenance-counters", !this.isAcceptedAndStarted || this.isClosed),
                text: "MaintenanceCounters"
            },
            {
                id: "MaintenanceTasks",
                disabled: false,
                disabledAdd: false,
                display: this.detail.MaintenanceType === WorkOrderMaintenanceType.Pep || this.detail.MaintenanceType === WorkOrderMaintenanceType.Regular,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: "",
                linkMain: this.genUrl("maintenance-tasks", !this.isAcceptedAndStarted || this.isClosed),
                text: "MaintenanceTasks"
            },
            {
                id: "BrakesAndTires",
                disabled: false,
                disabledAdd: false,
                display: this.detail.MaintenanceType === WorkOrderMaintenanceType.Pep || this.detail.MaintenanceType === WorkOrderMaintenanceType.Regular,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: "",
                linkMain: this.genUrl("maintenance-measures", !this.isAcceptedAndStarted || this.isClosed),
                text: "BrakesAndTires"
            },
            {
                id: "WorkDescription",
                disabled: false,
                disabledAdd: false,
                display: this.detail.ServiceType === ServiceDispatchType.Service,
                displayAdd: false,
                displayChevron: true,
                displayCount: true,
                count: this.detail.WorkDescriptionCount,
                linkAdd: "",
                linkMain: this.genUrl("work-description", true),
                text: "pageTitle_Service_Detail_Work_Description"
            },
            {
                id: "ServiceCallTechnicalDetails",
                disabled: false,
                disabledAdd: false,
                display: this.detail.ServiceType === ServiceDispatchType.Service,
                displayAdd: false,
                displayChevron: true,
                displayCount: true,
                count: this.detail.ServiceCallTechnicalDetailsCount,
                linkAdd: "",
                linkMain: this.genUrl("technical-details", true),
                text: "pageTitle_Service_Detail_Technical_Details"
            },
            {
                id: "contact",
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd: false,
                displayChevron: true,
                displayCount: true,
                count: this.detail.ContactCount,
                linkAdd: "",
                linkMain: this.genUrl("contact", this.isClosed),
                text: "Contacts"
            },
            {
                id: "equipment",
                disabled: false,
                disabledAdd: false,
                display: this.detail.ServiceType === ServiceDispatchType.Service,
                displayAdd: false,
                displayChevron: true,
                displayCount: true,
                count: this.detail.EquipmentCount,
                linkAdd: "",
                linkMain: this.genUrl("equipment", !this.isAcceptedAndStarted || this.isClosed),
                text: "Equipment"
            },
            {
                id: "ServiceEquipmentMaintenanceTasks",
                disabled: false,
                disabledAdd: false,
                display: this.detail.ServiceType === ServiceDispatchType.Service && this.detail.MaintenanceEquipmentCount > 0,
                displayAdd: false,
                displayChevron: true,
                displayCount: true,
                count: this.detail.MaintenanceEquipmentCount,
                linkAdd: "",
                linkMain: this.genUrl("equipment/maintenance", !this.isAcceptedAndStarted || this.isClosed),
                text: "ServiceEquipmentMaintenanceTasks"
            },
            {
                id: "note",
                disabled: (!this.isAcceptedAndStarted && this.detail.ServiceType === ServiceDispatchType.Service),
                disabledAdd: !this.isAcceptedAndStarted,
                display: true,
                displayAdd: !(this.isReadonly || this.isClosed),
                displayChevron: this.isReadonly || this.isClosed,
                displayCount: true,
                count: this.detail.NotesCount,
                linkAdd: this.genUrl("note/edit", this.isClosed),
                linkMain: this.genUrl("note", this.isClosed),
                text: !this.detail.MaintenanceType ? "VisitDetails" : "PepDetails"
            },
            {
                id: "reservedMaterial",
                disabled: false,
                disabledAdd: this.isAcceptedAndStarted,
                display: this.detail.MaterialSectionVisible,
                displayAdd: !(this.isReadonly || this.isClosed) && !this.isAcceptedAndStarted,
                displayChevron: this.isReadonly || this.isClosed,
                displayCount: true,
                count: this.materialReservedCountToDisplay,
                linkAdd: this.genUrl("material/edit/false/-1", this.isClosed),
                linkMain: this.genUrl("material-list", this.isClosed),
                text: this.materialReservedLinkTitle
            },
            {
                id: "material",
                disabled: this.detail.MaterialCount === 0 && !this.isAcceptedAndStarted,
                disabledAdd: !this.isAcceptedAndStarted,
                display: this.detail.BillingMaterialSectionVisible || this.detail.MaterialSectionVisible,
                displayAdd: !(this.isReadonly || this.isClosed) && this.isAcceptedAndStarted,
                displayChevron: this.isReadonly || this.isClosed,
                displayCount: true,
                count: this.materialCountToDisplay,
                linkAdd: this.genUrl("material/edit/false/-1", this.isClosed),
                linkMain: this.genUrl("material", this.isClosed || (!this.isClosed && !this.isAcceptedAndStarted)),
                text: this.materialLinkTitle
            },
            {
                id: "labor",
                disabled: !this.isAcceptedAndStarted,
                disabledAdd: !this.isAcceptedAndStarted,
                display: this.detail.LaborSectionVisible,
                displayAdd: !(this.isReadonly || this.isClosed),
                displayChevron: this.isReadonly || this.isClosed,
                displayCount: true,
                count: this.detail.TimesheetsCount,
                linkAdd: this.genUrl("labor/edit", this.isClosed),
                linkMain: this.genUrl("labor", this.isClosed),
                text: "Labor"
            },
            {
                id: "materialRequisition",
                disabled: false,
                disabledAdd: false,
                display: this.userAccessService.isOptionEnabledSync(this.enumHelper.userOptions.SERVICEMATERIALREQUISITION),
                displayAdd: !(this.isReadonly || this.isClosed),
                displayChevron: this.isReadonly || this.isClosed,
                displayCount: true,
                count: this.detail.RequisitionCount,
                linkAdd: this.genUrl("materialRequisition/edit"),
                linkMain: this.genUrl("materialRequisition", this.isClosed),
                text: "MaterialRequisition"
            },
            {
                id: "orders",
                disabled: false,
                disabledAdd: false,
                display: this.purchaseOrdersEnabled && this.detail.OrderSectionVisible,
                displayAdd: !(this.isReadonly || this.isClosed),
                displayChevron: this.isReadonly || this.isClosed,
                displayCount: true,
                count: this.detail.OrdersCount,
                linkAdd: this.genUrl("materialOrder/edit"),
                linkMain: this.genUrl("materialOrder", this.isClosed),
                text: "Orders"
            },
            {
                id: "workTasks",
                disabled: false,
                disabledAdd: true,
                display: this.detail.ServiceType === ServiceDispatchType.Workorder && this.detail.TaskSectionVisible, //TODO DONT SHOW IF MAINTENANCE
                displayAdd: false,
                displayChevron: this.isReadonly,
                displayCount: true,
                count: this.detail.WorkTasksCount,
                linkAdd: this.genUrl("workTask"),
                linkMain: this.genUrl("workTask"),
                text: "WorkTask"
            },
            {
                id: "recommendation",
                disabled: false,
                disabledAdd: false,
                display: this.userAccessService.isOptionEnabledSync(this.enumHelper.userOptions.SERVICERECOMMENDATION),
                displayAdd: !(this.isReadonly || this.isClosed),
                displayChevron: this.isReadonly || this.isClosed,
                displayCount: true,
                count: this.detail.RecommendationCount,
                linkAdd: this.genUrl("recommendation/edit", this.isClosed),
                linkMain: this.genUrl("recommendation", this.isClosed),
                text: "Recommendations"
            },
            {
                id: "inspections",
                disabled: !this.isAcceptedAndStarted,
                disabledAdd: !this.isAcceptedAndStarted,
                display: this.serviceType === ServiceDispatchType.Service && this.userAccessService.isOptionEnabledSync(this.enumHelper.userOptions.SERVICEINSPECTION),
                displayAdd: !(this.isReadonly || this.isClosed),
                displayChevron: this.isReadonly || this.isClosed,
                displayCount: true,
                count: this.detail.InspectionCount,
                linkAdd: routerHelper.generate(this.routeRepository.routes.Service_Call_Inspections_Add.name, { serviceType: this.serviceType, dispatchId: this.dispatchId}),
                linkMain: routerHelper.generate(this.routeRepository.routes.Service_Call_Inspections.name, { serviceType: this.serviceType, dispatchId: this.dispatchId}),
                text: "Inspections"
            },
            {
                id: "sameAddressServiceCalls",
                disabled: false,
                disabledAdd: false,
                display: this.serviceType === ServiceDispatchType.Service,
                displayAdd: false,
                displayChevron: true,
                displayCount: true,
                count: this.detail.ServiceCallsSameAddressCount || 0,
                linkAdd: "",
                linkMain: this.genUrl("sameAddressCalls", this.isClosed),
                text: "SameAddressServiceCalls"
            },
            {
                id: "document",
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd:  !this.isReadonly && !!this.detail.AssignedDate,
                displayCount: false,
                count: 0,
                linkAdd: this.genUrl("document/add"),
                linkMain: this.genReadonlyUrl("document", false),
                text: "Pictures/Documents"
            },
            {
                id: "additionalFields",
                disabled: false,
                disabledAdd: true,
                display: this.serviceType === ServiceDispatchType.Service,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: "",
                linkMain: this.genUrl("additionalFieldsServiceCallContract", this.isClosed || !this.isAcceptedAndStarted),
                text: `${this.i18n.tr('AdditionalFields')} - ${this.i18n.tr('Contract')}`
            },
            {
                id: "additionalFields",
                disabled: false,
                disabledAdd: true,
                display: this.serviceType === ServiceDispatchType.Service,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: "",
                linkMain: this.genUrl("additionalFieldsServiceCall", this.isClosed || !this.isAcceptedAndStarted || !this.detail.CreatedByMobile),
                text: `${this.i18n.tr('AdditionalFields')} - ${this.i18n.tr('Call')}`
            },
            {
                id: "additionalFields",
                disabled: !this.isAcceptedAndStarted && this.serviceType == ServiceDispatchType.Service,
                disabledAdd: true,
                display: this.serviceType !== ServiceDispatchType.Service || this.detail.Presence === this.enumPresence.ARRIVED.id || this.detail.Presence === this.enumPresence.COMPLETED.id,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: "",
                linkMain: this.genUrl("additionalFields", this.isClosed  || !this.isAcceptedAndStarted),
                text: `${this.i18n.tr('AdditionalFields')} - ${this.i18n.tr('CurrentVisit')}`
            },
            {
                id: "additionalFields",
                disabled: !this.isAcceptedAndStarted && this.serviceType == ServiceDispatchType.Service,
                disabledAdd: true,
                display: this.serviceType === ServiceDispatchType.Workorder && this.detail.TypeId !== "",
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: "",
                linkMain: this.genUrl("additionalFieldsType", this.isClosed  || !this.isAcceptedAndStarted),
                text: `${this.i18n.tr('AdditionalFields')} (${this.detail.TypeId})`
            },
            {
                id: "skillCertificate",
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: this.genUrl("skillCertificate", this.isClosed),
                linkMain: this.genUrl("skillCertificate", this.isClosed),
                text: "SkillsAndCertificates"
            },
            {
                id: "summary",
                disabled: !this.isAcceptedAndStarted,
                disabledAdd: !this.isAcceptedAndStarted,
                display: true,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: "",
                linkMain: this.genUrl("summary", this.isClosed),
                text: "Summary"
            },
            {
                id: "quotations",
                disabled: !this.isAcceptedAndStarted,
                disabledAdd: !this.isAcceptedAndStarted,
                display: (this.detail.ServiceType === ServiceDispatchType.Service && this.userAccessService.isOptionEnabledSync(this.enumHelper.userOptions.SERVICEQUOTATIONS)) || (this.userAccessService.isServiceQuotationBTOptionEnabledSync(this.enumHelper.userOptions.SERVICEQUOTATIONS) && this.detail.ServiceType === ServiceDispatchType.Workorder),
                displayAdd: await this.serviceCallQuotationSecurityService.canCreateQuotationInMobile(this.securitySettings),
                displayChevron: false,
                displayCount: true,
                count: this.detail.QuotationCount || 0,
                linkAdd: routerHelper.generate(this.routeRepository.routes.Service_Quotation_Details.name, { quotationId: NavigationNew, sourceServiceCallId: this.detail.ServiceType === ServiceDispatchType.Service ? this.detail.Id : null, dispatchId: this.dispatchId, isWorkOrder: this.detail.ServiceType === ServiceDispatchType.Service ? false : true, sourceWorkOrderId: this.detail.ServiceType === ServiceDispatchType.Workorder ? this.detail.Id : null }),
                linkMain: routerHelper.generate(this.routeRepository.routes.Service_Quotations_Mobile.name, { sourceServiceCallId: this.detail.ServiceType === ServiceDispatchType.Service ? this.detail.Id : null, dispatchId: this.dispatchId, sourceWorkOrderId: this.detail.ServiceType === ServiceDispatchType.Workorder ? this.detail.Id : null }),
                text: "Quotations"
            }
        ];
    }

    setDetail(data) {
        this.detail = this.adjustDataFromServer(data);
    }

    adjustDataFromServer(data) {
        if (data.AssignedDate) {
            var assDate = dateHelper.dateFromUTC(data.AssignedDate);
            assDate.setTime(assDate.getTime() + (data.TravelDuration * -60 * 60 * 1000));
            data.TravelStartDate = dateHelper.formatDateToSend(assDate);
        } else {
            data.TravelStartDate = "";
        }

        return data;
    }

    saveOnRoad(model) {
        var url = routerHelper.getRelativeUrl("action/road", model.TravelStartDate, model.AssignedDate, model.TimeIncrement);
        url += routerHelper.addQuerystring({
            readonly: this.isReadonly || this.isClosed
        });
        routerHelper.navigate(url);
    }

    async handleAlreadyInTravel(travelCheckData, model) {
        var message = travelCheckData.OtherServiceCallId ?
            this.i18n.tr("msg_AlreadyInTravelPart1ServiceCall").replace(new RegExp("{ID}", "g"), travelCheckData.OtherServiceCallId) :
            this.i18n.tr("msg_AlreadyInTravelPart1WorkOrder").replace(new RegExp("{ID}", "g"), travelCheckData.OtherWorkOrderId);
        message += this.detail.ServiceType === ServiceDispatchType.Service ?
            this.i18n.tr("msg_AlreadyInTravelPart2ServiceCall") :
            this.i18n.tr("msg_AlreadyInTravelPart2WorkOrder");

        const success = await notificationHelper.showDialogYesNo(message);

        if (success) {
            routerHelper.showLoading();
            try {
                await dispatchService.setServiceCallEnRouteStop(travelCheckData.OtherDispatchId).promise();
                model.TravelStartDate = travelCheckData.OtherDispatchTransportStartTime;
                this.rescheduleIfNotToday(true);
            } finally {
                routerHelper.hideLoading();
            }
        }
    }

    async validateOnRoad(model) {
        //validate if no other onroad...
        let data;
        routerHelper.showLoading();

        try {
            data = await dispatchService.getEnRouteCheck(model.DispatchId).promise();
        } finally {
            routerHelper.hideLoading();
        }

        if (data.OtherIsTeamLeader) {
            let warning = this.i18n.tr(data.OtherServiceCallId ?
                "err_YouAreTeamLeaderOnAnotherCall_Message" :
                "err_YouAreTeamLeaderOnAnotherWorkorder_Message");
            warning = warning.replace("{0}", data.OtherServiceCallId ? data.OtherServiceCallId : data.OtherWorkOrderId);
            warning += " " + this.i18n.tr("err_YouMustChangeThePersonInCharge_Message");

            notificationHelper.showWarning(warning);
        } else if (data.OtherDispatchId) {
            await this.handleAlreadyInTravel(data, model);
        } else {
            await this.rescheduleIfNotToday(true);
        }
    }

    async rescheduleIfNotToday(isRoadAction) {
        var _model = this.detail;
        if (this.canAcceptOrRefuse) {
            var assDate = undefined;

            if (!_model.AssignedDate || !dateHelper.isToday(_model.AssignedDate)) {
                var message = "";
                var thirdButton = this.i18n.tr("OtherDate");
                if (!dateHelper.isToday(_model.AssignedDate)) {
                    message = _model.ServiceType === ServiceDispatchType.Service ?
                        this.i18n.tr("RescheduleTodayConfirmationText") :
                        this.i18n.tr("RescheduleTodayConfirmationWorkOrderText");
                }
                if (!_model.AssignedDate) {
                    message = this.i18n.tr("DoYouWantToWorkOnThisCallToday");
                }

                const success = await notificationHelper.showDialogYesNo(message, "", false, {
                    thirdButton: true,
                    otherLabel: thirdButton
                });

                if (success === true) {
                    if (_model.AssignedDate) {
                        var _assignedDate = dateHelper.dateFromUTC(_model.AssignedDate);
                        var _today = new Date();
                        _today.setHours(_assignedDate.getHours());
                        _today.setMinutes(_assignedDate.getMinutes());
                        _today.setSeconds(0);
                        _today.setMilliseconds(0);
                        _model.AssignedDate = _today;
                    } else {
                        _model.AssignedDate = new Date();
                    }

                    assDate = dateHelper.formatDateToSend(_model.AssignedDate);
                    await this.saveAccept(_model, assDate);
                    if (isRoadAction) {
                        //callRoad
                        _model = this.detail;
                        await this.validateOnRoad(_model);
                    }
                } else if (success === "other") {
                    var url = routerHelper.navigateTo("Service_Assignation", this.serviceType, this.dispatchId);
                    url += routerHelper.addQuerystring({
                        // TODO Juste pas faire ça...
                        detail: JSON.stringify(this.detail),
                        subheaderTitle: this.subheaderTitle,
                        subheaderCssClass: this.subheaderCssClass
                    });
                    routerHelper.navigate(url);
                } else if (!isRoadAction) {
                    routerHelper.navigateBack();
                }
            } else {
                if (isRoadAction) {
                    //callRoad
                    this.saveOnRoad(_model);
                } else {
                    await this.saveAccept(_model);
                }
            }
        }
    }

    async initSecuritySettings() {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }

    genUrlToLoadSourceQuotation(quotationId) {
        return routerHelper.generate(this.routeRepository.routes.Service_Quotation_Mobile.name, { quotationId: quotationId });
    }

}