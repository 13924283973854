define([
    "jquery",
    "services/requests",
    "repositories/settingRepository",
    "helpers/apiHelper"
], function (jQuery, requests, settings, apiHelper) {
    "use strict";

    function getBaseUrl(serviceType) {
        return "api/companies/" + settings.getCompany() + (serviceType === "S" ? "/serviceCalls/" : "/workorders/");
    }

    function getCatalogBaseUrl(serviceType) {
        return "api/companies/" + settings.getCompany() + "/catalog" + (serviceType === "S" ? "/serviceCalls/" : "/workorders/");
    }

    var exports = {
        
        getBaseUrl: function (serviceType, dispatchId) {
            return getBaseUrl(serviceType) + "dispatch/" + dispatchId;
        },

        getCatalogBaseUrl: function(serviceType, dispatchId) {
            return getCatalogBaseUrl(serviceType) + "dispatch/" + dispatchId;
        },

        getCurrent: function (templateId) {
            var url = getBaseUrl("S") + "dispatch";
            return requests.getJson(apiHelper.appendQueryStringParameters(url, {'previous':'false', 'templateCode': templateId }));
        },

        getUnplanned: function (templateId, withinRange, unitSytem, latitude, longitude, emergencyId, searchString) {
            var url = getBaseUrl("S") + "dispatch";
            if (emergencyId) {
                if (searchString) {
                    return requests.getJson(apiHelper.appendQueryStringParameters(url, { 'previous': 'false', 'templateCode': templateId, 'unplannedOnly': true, 'withinRange': withinRange, 'unitSystem': unitSytem, 'latitude': latitude, 'longitude': longitude, 'emergencyId': emergencyId, 'filter': searchString }));
                }
                return requests.getJson(apiHelper.appendQueryStringParameters(url, { 'previous': 'false', 'templateCode': templateId, 'unplannedOnly': true, 'withinRange': withinRange, 'unitSystem': unitSytem, 'latitude': latitude, 'longitude': longitude, 'emergencyId': emergencyId }));
            }
            if (searchString) {
                return requests.getJson(apiHelper.appendQueryStringParameters(url, { 'previous': 'false', 'templateCode': templateId, 'unplannedOnly': true, 'withinRange': withinRange, 'unitSystem': unitSytem, 'latitude': latitude, 'longitude': longitude, 'emergencyId': emergencyId, 'filter': searchString}));
            }

            return requests.getJson(apiHelper.appendQueryStringParameters(url, { 'previous': 'false', 'templateCode': templateId, 'unplannedOnly': true, 'withinRange': withinRange, 'unitSystem': unitSytem, 'latitude': latitude, 'longitude': longitude}));
        },

        getCustomStatus: function (serviceType) {
            return requests.getJson(getBaseUrl(serviceType) + "customstatus");
        },

        getPrevious: function (page, searchString) {
            var url = getBaseUrl("S") + "dispatch?previous=true";
            if (page) {
                url += "&page=" + page;
            }
            if (searchString) {
                url += "&filter=" + searchString;
            }
            return requests.getJson(url);
        },

        getDetail: function (serviceType, dispatchId, fromHistory) {
            if (typeof fromHistory === 'undefined' || fromHistory === null) {
                return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId);
            }
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "?fromHistory=" + fromHistory);
        },

        getSameAddressCalls: function(templateId, dispatchId, serviceType) {
            var url = getBaseUrl("S") + "dispatch";
            return requests.getJson(apiHelper.appendQueryStringParameters(url,
                { 'templateCode': templateId, 'sameAddressDispatchId': dispatchId, 'type' : serviceType, 'unplannedOnly' : true }));
        },

        getStatus: function (serviceType, dispatchId) {
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/status");
        },

        setStatus: function (serviceType, dispatchId, customStatus) {
            return requests.post(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/setCustomstatus/"+ customStatus);
        },

        getInformation: function (serviceType, dispatchId) {
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/infos");
        },

        getActivities: function (serviceType, filter, page) {
            var url = getBaseUrl(serviceType) + "activities/";
            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }
            return requests.getJson(url);
        },

        getActivitiesByDispatch: function (serviceType, dispatchId, partType, filter, page) {
            var url = getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/activities/";
            if (partType !== "") {
                url += "?activityType=" + partType;
            }
            if (filter) {
                url += (partType !== "" ? "&" : "?") + "filter=" + filter;
            }
            if (page) {
                url += ((filter || partType !== "") ? "&" : "?") + "page=" + page;
            }
            return requests.getJson(url);
        },



        getSameAdressCallsHistory: function (serviceType, dispatchId, page, search, equipmentId) {
            var url = getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/history?page=" + page;

            if (search ) {
                 url += "&filter=" + search;
            }

            if (equipmentId) {
                url += "&equipmentId="+equipmentId;
            }

            return requests.getJson(url);
        },

        getCallVisitsHistory: function (serviceType, dispatchId, page, search, equipmentId) {
            var url = getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/visithistory?page=" + page;

            if (search) {
                url += "&filter=" + search;
            }

            if (equipmentId) {
                url += "&equipmentId=" + equipmentId;
            }

            return requests.getJson(url);
        },


        deleteLabor: function (serviceType, dispatchId, lineNo, timestamp) {
            return requests.remove(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/timesheets/" + lineNo + "?timesheetTimestamp=" + encodeURIComponent(timestamp));
        },

        getLabors: function (serviceType, dispatchId, fromHistory) {
            if (typeof fromHistory === 'undefined' || fromHistory === null) {
                return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/timesheets");
            }
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/timesheets" + "?fromHistory=" + fromHistory);
        },

        getLaborsHeader: function (serviceType, dispatchId, fromHistory) {
            if (typeof fromHistory === 'undefined' || fromHistory === null) {
                return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/timesheets/header");
            }
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/timesheets/header" + "?fromHistory=" + fromHistory);
        },

        getLabor: function (serviceType, dispatchId, lineNo) {
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/timesheets/" + lineNo);
        },

        setLabor: function (serviceType, dispatchId, data) {
            return requests.post(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/timesheets/", data);
        },

        deleteMaterial: function (serviceType, dispatchId, lineNo, isBilling, timestamp) {
            return requests.remove(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/materials/" + lineNo + "?billing=" + isBilling + (timestamp ? "&materialTimestamp=" + encodeURIComponent(timestamp): ""));
        },

        getMaterials: function (serviceType, dispatchId, fromHistory) {
            if (typeof fromHistory === 'undefined' || fromHistory === null) {
                return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/materials");
            }
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/materials" + "?fromHistory=" + fromHistory);
        },

        getReservedMaterials: function (serviceType, dispatchId, onlyAvailableQty) {
            if (typeof onlyAvailableQty === 'undefined' || onlyAvailableQty === null) {
                onlyAvailableQty = true;
            }
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/materials/reserved?onlyAvailableQty=" + onlyAvailableQty);
        },

        getMaterial: function (serviceType, dispatchId, lineNo, isBilling) {
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/materials/" + lineNo + "?billing=" + isBilling);
        },

        getIsReservedMaterials: function (serviceType, dispatchId) {
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/materials/options");
        },

        setReservedMaterials: function (serviceType, dispatchId) {
            return requests.post(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/materials/reserved");
        },

        setMaterial: function (serviceType, dispatchId, data) {
            return requests.post(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/materials/", data);
        },



        getNote: function (serviceType, dispatchId, rowNumber) {
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/notes/" + rowNumber);
        },

        getNotes: function (serviceType, dispatchId) {
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/notes");
        },

        delNote: function (serviceType, dispatchId, lineNo, visitTimestamp, workOrderTimestamp) {
            return requests.remove(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/notes/" + lineNo +
                (workOrderTimestamp ? 
                "?workOrderTimestamp=" + encodeURIComponent(workOrderTimestamp)
                :
                "?VisitTimestamp=" + encodeURIComponent(visitTimestamp)));
        },

        setNotes: function (serviceType, dispatchId, data) {
            return requests.post(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/notes", data);
        },



        getEquipments: function (serviceType, dispatchId, filter, page) {
            return requests.getJson(
                apiHelper.appendFilterPagingParams(
                    getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/equipment",
                    filter,
                    page)
            );
        },

        getEquipmentsForMaintenance: function (serviceType, dispatchId, filter) {
            return requests.getJson(
                apiHelper.appendFilterPagingParams(
                    getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/equipment/maintenance", filter)
            );
        },

        getEquipmentDetail: function (serviceType, callNumber, equipmentCode) {
            return requests.getJson(getBaseUrl(serviceType) + callNumber + "/equipment/" + equipmentCode);
        },

        getEquipmentDetails: function (serviceType, dispatchId, equipmentId) {
            return requests.getJson(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/equipment/" + equipmentId);
        },

        changeEquipmentUsed: function (serviceType, dispatchId, data) {
            return requests.post(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/equipment", data);
        },

        setSignature: function (serviceType, dispatchId, data) {
            return requests.post(getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/signature", data);
        },

        getLastShiftUsed: function (serviceType) {
            return requests.getJson(getBaseUrl(serviceType) + "timesheets/lastShift");
        },

        getWorkorderActivitiesByTypes: function (workOrderId, activityType, filter, page) {
            var url = getBaseUrl("W") + workOrderId + "/activities/?activityType=" + activityType;
            if (filter) {
                url += "&filter=" + filter;
            }
            if (page) {
                url += "&page=" + page;
            }
            return requests.getJson(url);
        },

        getSummaryFromDispatchId: function (serviceType, dispatchId, language, fromHistory) {
            var url = getBaseUrl(serviceType) + "dispatch/" + dispatchId + "/summary";
            if (language) {
                url = url + "?lang=" + language;
            }

            if (typeof fromHistory === 'undefined' || fromHistory === null) {
                return requests.getJson(url);
            }

            url = url + "?fromHistory=" + fromHistory;
            return requests.getJson(url);
        },

        getSummaryFromId: function (serviceType, id, assignedDate, language) {
            var url = getBaseUrl(serviceType) + id + (assignedDate ? "/assignedDate/" + assignedDate : "") + "/summary";
            if (language) {
                url = url + "?lang=" + language;
            }

            return requests.getJson(url);
        },

        addCall: function (templateId, contractId, data) {
            return requests.post(getBaseUrl("S") + "dispatch?templateCode="+templateId + "&contractId=" + contractId, data);
        },

        getGroup: function (filter, page) {
            var url = apiHelper.getBaseUrl() + "/groups";
            url = apiHelper.appendFilterPagingParams(url, filter, page);
            return requests.getJson(url);
        },

        getDispatchFromId: function (serviceType, id, assignedDate) {
            var url = getBaseUrl(serviceType) + id + (assignedDate ? "/assignedDate/" + assignedDate : "") + "/get-dispatch-id";
            return requests.getJson(url);
        },
    };

    return exports;
});
