import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { CreationSource } from "api/enums/creation-source";
import { LocationType } from "api/enums/location-type";
import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { ServiceCallQuotationSortOrder } from "api/enums/service-call-quotation-sort-order";
import { WageTypeString } from "api/enums/wage-type-string";
import { ClientModel } from "api/models/company/client/client-model";
import { DocumentModel } from "api/models/company/document/document-model";
import { LocationModel } from "api/models/company/location-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationEquipmentModel } from "api/models/company/service-call/service-call-quotation-equipment-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { ServiceCallQuotationSaveModel } from "api/models/company/service-call/service-call-quotation-save-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallQuotationProxy } from "api/proxies/service-call-quotation-proxy";
import { autoinject } from "aurelia-framework";
import { Compare } from "helpers/compare";
import { IRequestConfig } from "models/request-config";
import { CatalogService } from "services/catalog-service";
import { LocationService } from "services/location-service";
import { ServiceCallQuotationCustomerProjetContractModel } from "api/models/company/service-call/service-call-quotation-customer-projet-contract-model";
import { ServiceCallQuotationUserValidationModel } from "api/models/company/service-call/service-call-quotation-user-validation-model";
import apiHelper from "helpers/apiHelper";
import additionalFieldsService from "services/additionalFieldsService";

@autoinject()
export class ServiceCallQuotationService {
    constructor(
        private readonly serviceCallQuotationProxy: ServiceCallQuotationProxy,
        private readonly catalogService: CatalogService,
        private readonly locationService: LocationService
    ) {
    }

    public async getQuotations(sortOrder: ServiceCallQuotationSortOrder | null, sourceServiceCallId: number | null, statuses: Array<string | null> | null, excludedStatuses: string[] | null, currentPrefixOnly: boolean, currentUserOnly: boolean, sourceWorkOrderId: string | null | undefined): Promise<ServiceCallQuotationDetailsModel[]> {
        return await this.serviceCallQuotationProxy.GetQuotations(sortOrder ? sortOrder : undefined, sourceServiceCallId, statuses, excludedStatuses, currentPrefixOnly, currentUserOnly, sourceWorkOrderId) || [];
    }

    public async getQuotation(quotationId: number, includeItems: boolean | null, includeEquipments: boolean | null, includeDocuments: boolean | null, includeSignatureDocuments: boolean | null, useCustomerLanguage: boolean | null): Promise<ServiceCallQuotationDetailsModel> {
        // TODO Handle null;
        return await this.serviceCallQuotationProxy.GetQuotation(quotationId, includeItems ? includeItems : undefined, includeEquipments ? includeEquipments : undefined, includeDocuments ? includeDocuments : undefined, includeSignatureDocuments ? includeSignatureDocuments : undefined, useCustomerLanguage ? useCustomerLanguage : undefined) as ServiceCallQuotationDetailsModel;
    }

    public async getQuotationItem(quotationItemId: number): Promise<ServiceCallQuotationItemModel> {
        // TODO Handle null;
        return await this.serviceCallQuotationProxy.GetQuotationItem(quotationItemId) as ServiceCallQuotationItemModel;
    }

    public saveQuotation(quotation: ServiceCallQuotationDetailsModel, saveItems: boolean, saveEquipments: boolean): Promise<number> {
        const saveModel = this.getSaveModel(quotation);
        return this.serviceCallQuotationProxy.SaveQuotation(saveModel, saveItems, saveEquipments);
    }

    public async saveWorkDescription(quotationId: number, workDescription: string): Promise<void> {
        await this.serviceCallQuotationProxy.SaveWorkDescription(quotationId, workDescription);
    }

    public async saveIsLockedQuotation(quotationId: number, isLockedQuotation: boolean): Promise<void> {
        await this.serviceCallQuotationProxy.SaveIsLockedQuotation(quotationId, isLockedQuotation);
    }

    public async savePricingType(quotationId: number, isLumpSum: string, lumpSum: number | null): Promise<void> {
        await this.serviceCallQuotationProxy.SavePricingType(quotationId, isLumpSum, lumpSum);
    }

    public getSaveModel(quotation: ServiceCallQuotationDetailsModel): ServiceCallQuotationSaveModel {
        // TODO: Address fields
        const saveModel: ServiceCallQuotationSaveModel = {
            QuotationContext: quotation.QuotationContext,
            Id: quotation.Id,
            Number: quotation.Number,
            Status: quotation.Status,
            CreationSource: quotation.CreationSource,
            TypeId: quotation.TypeId,
            Description: quotation.Description,
            DetailedDescription: quotation.DetailedDescription,
            Date: quotation.Date,
            DateSubmitted: quotation.DateSubmitted,
            MaxResponseDate: quotation.MaxResponseDate,
            FollowupDate: quotation.FollowupDate,
            DateAccepted: quotation.DateAccepted,
            RequiredDateType: quotation.RequiredDateType,
            RequiredDate: quotation.RequiredDate,
            ExpectedDuration: quotation.ExpectedDuration,
            MaximumDelay: quotation.MaximumDelay,
            TechnicianId: quotation.TechnicianId,
            RequiredSkillId: quotation.RequiredSkillId,
            OwnerUserId: quotation.OwnerUserId,
            AssignedEstimatorId: quotation.AssignedEstimatorId,
            ContractId: quotation.ContractId,
            CustomerId: quotation.CustomerId,
            ContactId: quotation.ContactId,
            ContactName: quotation.ContactName,
            ContactEmail: quotation.ContactEmail,
            CustomerOrderNumber: quotation.CustomerOrderNumber,
            SourceServiceCallId: quotation.SourceServiceCallId,
            SourceDispatchId: quotation.SourceDispatchId,
            EmergencyId: quotation.EmergencyId,
            IsLumpSum: quotation.IsLumpSum,
            LumpSum: quotation.LumpSum,
            SalespersonId: quotation.SalespersonId,
            SignatureDocumentId: quotation.SignatureDocumentId,
            SignatureName: quotation.SignatureName,
            SignatureEmail: quotation.SignatureEmail,
            RefusalReason: quotation.RefusalReason,
            InternalNotes: quotation.InternalNotes,

            AddressType: quotation.Address ? quotation.Address.Type : " ",
            Address_AlternativeAddressId: quotation.Address_AlternativeAddressId,
            Address_ContactId: quotation.Address_ContactId,
            Address_CustomerLocation_ContactId: quotation.Address_CustomerLocation_ContactId,
            Address_CustomerAddressId: quotation.Address_CustomerAddressId,

            TotalCost: quotation.TotalCost,
            TotalSelling: quotation.TotalSelling,
            Tax: quotation.Tax,
            TaxIncluded: quotation.TaxIncluded,
            Total: quotation.Total,
            Tax1: quotation.Tax1,
            Tax2: quotation.Tax2,
            IsUsaTaxExempt: quotation.IsUsaTaxExempt,
            UsaTaxExemptionCode: quotation.UsaTaxExemptionCode,
            Items: quotation.Items,
            Equipments: quotation.Equipments,
            EquipmentsToAdd: quotation.EquipmentsToAdd,
            ProjectId: quotation.ProjectId,
            GeneratedWorkOrderId: quotation.GeneratedWorkOrderId,
            SourceWorkOrderId: quotation.SourceWorkOrderId,
            PriorityId: quotation.PriorityId,
            ProjectIdWorkLocation: quotation.ProjectIdWorkLocation,
            ServiceEquipmentId: quotation.ServiceEquipmentId,
            DeliveryDelay: quotation.DeliveryDelay,
            ProfitMargin: quotation.ProfitMargin,
            ServiceCallType: quotation.ServiceCallType,
            MustCreateCustomerContract: quotation.MustCreateCustomerContract,
            MustCreateProject: quotation.MustCreateProject,
            IsContractMiscCustomer: quotation.IsContractMiscCustomer,
            ServiceContractProjectId: quotation.ServiceContractProjectId,
            AdditionalFields: quotation.AdditionalFields,
            AutomaticDateTimeLastModification: quotation.AutomaticDateTimeLastModification,
            InvoicingMode: quotation.InvoicingMode
        };

        return saveModel;
    }

    public buildServiceCallQuotationEquipmentModel(equipmentId: string, addedEquipmentDesc: string | null): ServiceCallQuotationEquipmentModel {
        return {
            Id: -1,
            Brand: null,
            Description: addedEquipmentDesc,
            QuotationId: 0,
            LineNumber: -1,
            ContractEquipmentId: 0,
            EquipmentId: equipmentId,
            EquipmentCount: 0,
            Location: null,
            Model: null,
            SerialNumber: null,
            UnitType: null
        };
    }

    public async deleteQuotation(quotationId: number): Promise<void> {
        await this.serviceCallQuotationProxy.DeleteQuotation(quotationId);
    }

    public async putQuotationOnHold(quotationId: number): Promise<void> {
        await this.serviceCallQuotationProxy.PutQuotationOnHold(quotationId);
    }

    public async resumeQuotation(quotationId: number): Promise<void> {
        await this.serviceCallQuotationProxy.ResumeQuotation(quotationId);
    }

    public async sendQuotationToOffice(quotationId: number): Promise<void> {
        await this.serviceCallQuotationProxy.CompleteQuotation(quotationId);
    }

    public async sendQuotationToCustomer(quotationId: number): Promise<void> {
        await this.serviceCallQuotationProxy.CompleteAndSubmitQuotation(quotationId);
    }

    public async completeAndAcceptQuotation(quotationId: number, signatureData: string, contactName: string | null, contactEmail: string | null): Promise<void> {
        const documentModel = {
            Comment: `quotation_${quotationId}_signature`,
            FileData: signatureData
        } as DocumentModel;

        await this.serviceCallQuotationProxy.CompleteAndAcceptQuotation(documentModel, quotationId, contactName, contactEmail);
    }

    public async refuseQuotation(quotationId: number, refusalReason: string): Promise<void> {
        await this.serviceCallQuotationProxy.RefuseQuotation(quotationId, refusalReason);
    }

    public async createNewQuotationDetails(status: string, creationSource: CreationSource, isWorkOrder: boolean, sourceServiceCallId: number | null | undefined, serviceCallContractEquipmentId: number | null | undefined, sourceDispatchId: number | null | undefined, sourceQuotationId: number | null | undefined, sourceWorkOrderId: string | null | undefined): Promise<ServiceCallQuotationDetailsModel> {
        return await this.serviceCallQuotationProxy.CreateNewQuotationDetails(status, creationSource, isWorkOrder, sourceServiceCallId, serviceCallContractEquipmentId, sourceDispatchId, sourceQuotationId, sourceWorkOrderId, undefined ) as ServiceCallQuotationDetailsModel;
    }

    public async saveQuotationItem(serviceCallQuotationItemModel: ServiceCallQuotationItemModel | null): Promise<void> {
        return await this.serviceCallQuotationProxy.SaveQuotationItem(serviceCallQuotationItemModel);
    }

    public async saveQuotationItems(quotationItems: Array<ServiceCallQuotationItemModel | null> | null): Promise<void> {
        return await this.serviceCallQuotationProxy.SaveQuotationItems(quotationItems);
    }

    public async deleteQuotationItem(quotationItemId: number): Promise<void> {
        await this.serviceCallQuotationProxy.DeleteQuotationItem(quotationItemId);
    }

    public async serviceContractValidation(creationSource: CreationSource, dispatchId: number | null | undefined): Promise<boolean> {
        return await this.serviceCallQuotationProxy.ServiceContractValidation(creationSource, dispatchId);
    }

    public createNewQuotationItem(quotationId: number, type: ServiceCallQuotationItemType, profitMargin: number = 0, activityId?: string | null, occupationCategoryId?: number, quantity?: number, description?: string | null): ServiceCallQuotationItemModel {
        return {
            Id: 0,
            ParentId: 0,
            QuotationId: quotationId,
            LineNumber: 0,
            Type: type,
            Description: description ? description : "",
            ActivityId: activityId ? activityId : null,
            OccupationCategoryId: occupationCategoryId ? occupationCategoryId : 0,
            BonusId: null,
            BonusDescription: null,
            RateType: (type === ServiceCallQuotationItemType.Labor) ? WageTypeString.Simple : "",
            CatalogId: null,
            OutCatalogCode: null,
            CatalogDescription1: "",
            CatalogDescription2: "",
            CatalogDescription3: "",
            EquipmentId: null,
            EquipmentTypeId: null,
            CatalogBillingId: null,
            RevenueType: null,
            Quantity: quantity ? quantity : 1,
            UnitCost: 0,
            TotalCost: 0,
            UnitPrice: 0,
            DiscountRate: 0,
            Discount: 0,
            TotalPrice: 0,
            IsBillable: true,
            NonBillableReasonId: null,
            NonBillableReasonDescription: "",
            BillableAmount: 0,
            Tax1AccountId: 0,
            Tax1: 0,
            Tax1IncludedOrExcluded: null,
            Tax1FederalOrProvincial: null,
            Tax1Rate: 0,
            Tax1PercentageRecoverable: 0,
            Tax1Priority: 0,
            Tax2AccountId: 0,
            Tax2: 0,
            Tax2IncludedOrExcluded: null,
            Tax2FederalOrProvincial: null,
            Tax2Rate: 0,
            Tax2PercentageRecoverable: 0,
            Tax2Priority: 0,
            TaxFormulaKey: "",
            FinalBillableAmount: 0,
            Prefix: null,
            Comment: null,
            BilledQuantity: 0,
            InvoiceDescription: "",
            ActivityDescription: "",
            OccupationCategoryDescription: "",
            EquipmentDescription: "",
            LinkedItems: [],
            CostManagementMode: "",
            SectorId: null,
            SectorDesciption: null,
            TradeId: 0,
            TradeDescription: null,
            ApprenticeshipYear: 0,
            ApprenticeshipYearDescription: null,
            AnnexId: null,
            AnnexDescription: null,
            RegionId: 0,
            RegionDescription: null,
            ShiftId: 0,
            ShiftDescription: null,
            ProfitMargin: (type === ServiceCallQuotationItemType.Labor || type === ServiceCallQuotationItemType.Material || type === ServiceCallQuotationItemType.Equipment) ? profitMargin : 0,
            InventoryScaleFactor: 0,
            CurrencyCode: "",
            CurrencyRate: 0,
            CurrencyUnitPrice: 0,
            InternalNotes: null,
            IsDefaultItem: false
        };
    }

    public sortQuotations(quotations: ServiceCallQuotationDetailsModel[], sortOrder: ServiceCallQuotationSortOrder): ServiceCallQuotationDetailsModel[] {
        // TODO: Le tri devra se faire sur le serveur
        switch (sortOrder) {
            case ServiceCallQuotationSortOrder.Date:
                return quotations.sort((a: ServiceCallQuotationDetailsModel, b: ServiceCallQuotationDetailsModel) => Compare.NullableDates(a.Date, b.Date));
            case ServiceCallQuotationSortOrder.EmergencyLevel:
                return quotations.sort((a: ServiceCallQuotationDetailsModel, b: ServiceCallQuotationDetailsModel) => Compare.NullableStrings(a.EmergencyId, b.EmergencyId));
            case ServiceCallQuotationSortOrder.RequiredDate:
                return quotations.sort((a: ServiceCallQuotationDetailsModel, b: ServiceCallQuotationDetailsModel) => Compare.NullableDates(a.RequiredDate, b.RequiredDate));
            case ServiceCallQuotationSortOrder.Type:
                return quotations.sort((a: ServiceCallQuotationDetailsModel, b: ServiceCallQuotationDetailsModel) => Compare.NullableStrings(a.TypeId, b.TypeId));
            case ServiceCallQuotationSortOrder.Status:
                return quotations.sort((a: ServiceCallQuotationDetailsModel, b: ServiceCallQuotationDetailsModel) => Compare.NullableStrings(a.Status, b.Status));
            case ServiceCallQuotationSortOrder.DateDescending:
                return quotations.sort((a: ServiceCallQuotationDetailsModel, b: ServiceCallQuotationDetailsModel) => Compare.NullableDatesDescending(a.Date, b.Date));
            default:
                return quotations;
        }
    }

    public filterQuotations(quotations: ServiceCallQuotationDetailsModel[], searchValue: string): ServiceCallQuotationDetailsModel[] {
        searchValue = searchValue.toUpperCase();

        // TODO: Le filtre devra se faire sur le serveur
        return quotations.filter((x: ServiceCallQuotationDetailsModel) =>
            (x.Number && x.Number.toUpperCase().includes(searchValue)) ||
            (x.Description && x.Description.toUpperCase().includes(searchValue)) ||
            (x.TypeId && x.TypeId.toUpperCase().includes(searchValue)) ||
            (x.TypeDescription && x.TypeDescription.toUpperCase().includes(searchValue)) ||
            (x.EmergencyId && x.EmergencyId.toUpperCase().includes(searchValue)) ||
            (x.EmergencyDescription && x.EmergencyDescription.toUpperCase().includes(searchValue)) ||
            (x.ContractId && x.ContractId.toUpperCase().includes(searchValue)) ||
            (x.CustomerId && x.CustomerId.toUpperCase().includes(searchValue)) ||
            (x.CustomerDescription && x.CustomerDescription.toUpperCase().includes(searchValue)));
    }

    public async createQuotationItemLinkedItems(quotationId: number, contractId: string | null, catalogItemId: string, quantity: number): Promise<ServiceCallQuotationItemModel[] | null> {
        return await this.serviceCallQuotationProxy.CreateQuotationItemLinkedItems(quotationId, contractId, catalogItemId, quantity);
    }

    public async getAvailableWorkLocations(addressType: string, quotation: ServiceCallQuotationDetailsModel, filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<LocationModel[] | null> {
        let id: string | null = null;

        if (addressType === LocationType.Contract) {
            if (!quotation.ContractId) { return []; }
            id = quotation.ContractId;
        } else if (addressType === LocationType.Project) {
            if (!quotation.ProjectId) { return []; }
            id = quotation.ProjectId;
        } else if (addressType === LocationType.Customer || addressType === LocationType.CustomerSite) {
            if (!quotation.CustomerId) { return []; }
            id = quotation.CustomerId;
        }

        return await this.locationService.getLocations(addressType, id, null, filter, pagingInfo, requestConfig);
    }

    public clearAddress(quotation: ServiceCallQuotationDetailsModel): void {
        quotation.Address = null;
        quotation.Address_AlternativeAddressId = 0;
        quotation.Address_ContactId = 0;
        quotation.Address_CustomerLocation_ContactId = 0;
        quotation.Address_CustomerAddressId = 0;
    }

    public assignAddressIdsForQuotation(quotation: ServiceCallQuotationDetailsModel, location: LocationModel | null): void {
        quotation.Address_AlternativeAddressId = 0;
        quotation.Address_ContactId = 0;
        quotation.Address_CustomerLocation_ContactId = 0;
        quotation.Address_CustomerAddressId = 0;
        quotation.Address = location;

        if (location) {
            if (location.Type === LocationType.Alternative && location.NumericId) {
                quotation.Address_AlternativeAddressId = location.NumericId;
            } else if (location.Type === LocationType.Contact && location.NumericId) {
                quotation.Address_ContactId = location.NumericId;
            } else if (location.Type === LocationType.Customer && location.NumericId2) {
                quotation.Address_CustomerAddressId = location.NumericId2;
            } else if (location.Type === LocationType.CustomerSite && location.NumericId2) {
                quotation.Address_ContactId = location.NumericId2;
            }
        }
    }

    public async getAvailableCustomers(includeInactive: boolean, contractId: string | null, projectId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ClientModel[]> {
        return await this.serviceCallQuotationProxy.GetAvailableCustomersForQuotation(includeInactive, (contractId === null || contractId === undefined || contractId === "") ? null : contractId, (projectId === null || projectId === undefined || projectId === "") ? null : projectId, filter, pagingInfo, requestConfig) || [];
    }

    public async UpdateQuotationItems(quotationItems: Array<ServiceCallQuotationItemModel | null> | null): Promise<Array<ServiceCallQuotationItemModel | null> | null> {
        if (quotationItems === null) {
            return null;
        }

        // Assign temporary ids to new items.
        let minId: number = Math.min(...quotationItems.map((item: ServiceCallQuotationItemModel) => item.Id)) ;
        let nextId = minId < 0 ? --minId : -1;

        quotationItems.forEach((item: ServiceCallQuotationItemModel) => {
            if (item.Id === 0) {
                item.Id = nextId--;

                if (item.LinkedItems !== null) {
                    item.LinkedItems.forEach((childItem: ServiceCallQuotationItemModel) => {
                        childItem.Id = nextId--;
                        childItem.ParentId = item.Id;
                    });
                }
            }
        });

        // Flatten linked items.
        quotationItems.forEach((item: ServiceCallQuotationItemModel, index: number) => {
            if (item.LinkedItems !== null && item.LinkedItems.length > 0) {
                item.LinkedItems.forEach((linkItem: ServiceCallQuotationItemModel) => {
                    quotationItems.splice(index + 1, 0, linkItem);
                });
                item.LinkedItems = [];
            }
        });

        // Assign line numbers.
        let nextLineNumber: number = 1;
        quotationItems.forEach((item: ServiceCallQuotationItemModel) => {
            item.LineNumber = nextLineNumber++;
        });

        return quotationItems;
    }

    public async GetQuotationItemsFromContract(quotation: ServiceCallQuotationDetailsModel, pricingTypeChange: boolean = false): Promise<Array<ServiceCallQuotationItemModel | null> | null > {
        if (!quotation.ContractId) {
            return quotation.Items;
        }

        let contractAllItems: ServiceCallQuotationItemModel[] | null = new Array<ServiceCallQuotationItemModel>();
        if (pricingTypeChange) {
            contractAllItems = await this.serviceCallQuotationProxy.GetContractQuotationItems(quotation.ContractId, (quotation.CustomerId === null || quotation.CustomerId === undefined || quotation.CustomerId === "") ? null : quotation.CustomerId, null);
        }

        const contractItems = await this.serviceCallQuotationProxy.GetContractQuotationItems(quotation.ContractId, (quotation.CustomerId === null || quotation.CustomerId === undefined || quotation.CustomerId === "") ? null : quotation.CustomerId, (quotation.IsLumpSum === null || quotation.IsLumpSum === undefined) ? null : quotation.IsLumpSum);

        let manualAddedItems = new Array<ServiceCallQuotationItemModel | null>();

        if (quotation.Items !== null) {
            const filteredItems = quotation.Items.filter((itemToKeep: ServiceCallQuotationItemModel) => itemToKeep.Type !== ServiceCallQuotationItemType.MiscCost && ((!pricingTypeChange && itemToKeep.Type !== ServiceCallQuotationItemType.BillingItem) || (!pricingTypeChange && itemToKeep.Type === ServiceCallQuotationItemType.BillingItem && !itemToKeep.IsDefaultItem) || (pricingTypeChange && !contractAllItems!.find((item: ServiceCallQuotationItemModel) => itemToKeep.CatalogId === item.CatalogId)) ));
            if (filteredItems !== null) {
                manualAddedItems = filteredItems;
            }
        }

        return this.UpdateQuotationItems(manualAddedItems.concat(contractItems !== null ? contractItems : new Array<ServiceCallQuotationItemModel>() ));
    }

    public async isProjectInvoicing(workOrderId: string): Promise<boolean> {
        return await this.serviceCallQuotationProxy.IsProjectInvoicing(workOrderId);
    }

    public async getSourceDispatchId(quotationId: number): Promise<number> {
        return await this.serviceCallQuotationProxy.GetSourceDispatchId(quotationId);
    }

    public async getUserGridStatusSelection(): Promise<Array<string | null> | null> {
        const setting = await this.serviceCallQuotationProxy.GetUserGridStatusSelection();
        if (!setting) {
            return [];
        }
        return setting.StatusSelection;
    }

    public async userQuotationValidation(): Promise<ServiceCallQuotationUserValidationModel | null> {
        return await this.serviceCallQuotationProxy.UserQuotationValidation();
    }

    public async setUserGridStatusSelection(statuses: Array<string | null> | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        return await this.serviceCallQuotationProxy.SetUserGridStatusSelection(statuses);
    }

    public async getServiceQuotationCustomerProjetContractInfos(quotationId: number, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationCustomerProjetContractModel> {
        return (await this.serviceCallQuotationProxy.GetServiceQuotationCustomerProjetContractInfos(quotationId, requestConfig))!;
    }

    public async createServiceQuotationCustomerProjetContract(customerProjectContractInfo: ServiceCallQuotationCustomerProjetContractModel, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationDetailsModel> {
        return (await this.serviceCallQuotationProxy.CreateServiceQuotationCustomerProjetContract(customerProjectContractInfo, requestConfig))!;
    }

    public async getAdditionalFieldsFromServiceContract(quotationId: number, contractNumber: string): Promise<any> {
        return await additionalFieldsService.getAdditionalFieldsFromServiceContract(quotationId, contractNumber);
    }

    public async checkIfRequiredAdditionalFieldsAreEntered(quotation: ServiceCallQuotationDetailsModel, forMobile: boolean): Promise<boolean> {
        return await this.serviceCallQuotationProxy.CheckIfRequiredAdditionalFieldsAreEntered(quotation.AdditionalFields, forMobile);
    }

}
